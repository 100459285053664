<template>
	<div id="layoutGen">

		<!-- HEADER-PAGE: full width top header -->
		<div id="header-page"></div>



		<!-- =============== FORMWRAPPER: full width, stretching block =============== -->
		<div id="formWrapper">

			<!-- CONTENTCOLUMN: The central Column -->
			<div id="contentColumn">

				<!-- HEADER: same width as the central column -->
				<div id="header-form" role="banner">
					<div id="heroimage" class="nl">
						<macaron v-if="false">
							<template v-slot:big><div v-html="$t('macaron.big')"></div></template>
							<template v-slot:small ><div v-html="$t('macaron.small')"></div></template>
						</macaron>	
					</div>
				</div>

				<div id="popup" style="display:none;" role="dialog">
					<popup></popup>
				</div>

				<!-- LANG -->
				<lang-switcher :inHeader="true"></lang-switcher>

				<!-- ========== FORMPAPER: all the pages ========== -->
				<div id="formPaper" role="main">
					<router-view></router-view>
				</div>

				<!-- ========== FOOTER-FORM: width of the central column ========== -->
				<div id="footer-form">
					<!-- <classic-footer></classic-footer> -->
				</div>
				<!-- ========== // ========== -->


			</div> <!-- End of #contentColumn -->
		</div> <!-- End of #formWrapper-->


		<!-- FOOTER-PAGE: full width bottom footer -->
		<div id="footer-page" role="contentinfo">
			<classic-footer></classic-footer>
		</div>

	</div> <!-- End of #layoutGen -->






	<!-- Custom GDPR Example -->
<!--
	<div class="gdpr-banner" :class="{visible : isGDPRVisible}">
		<div v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">
			Ces données personnelles vous sont demandées dans le cadre de l’action cashback de CLIENT. Nous conserverons ces données pendant 6 mois pour la bonne exécution de cette action. Les données personnelles indiquées dans ce formulaire seront traitées par CLIENT (CLIENTGROUP) dans le cadre de l’action cashback, conformément à la <a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>. Si vous avez des questions concernant le traitement de vos données personnelles, veuillez nous contacter via le <a :href="$t('footer.contact.link')" target="_blank" rel="noopener">{{$t('footer.contact.label')}}</a>. Vous pouvez également utiliser ce formulaire en ligne si vous souhaitez vous opposer au traitement ou exercer vos autres droits.
		</div>
		<div v-else>
			Deze persoonsgegevens worden u gevraagd in het kader van de cashback actie van CLIENT. We bewaren deze gegevens 6 maanden voor het correct uitvoeren van deze actie. De persoonsgegevens zoals aangeven op dit formulier worden verwerkt door CLIENT (CLIENTGROUP) ten behoeve van de cashback actie conform het <a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>. Indien u vragen heeft omtrent de verwerking van uw persoonsgegevens, neem dan contact op via het <a :href="$t('footer.contact.link')" target="_blank" rel="noopener">{{$t('footer.contact.label')}}</a>. U kan dit online formulier ook gebruiken indien u bezwaar wenst te maken tegen de verwerking of om uw andere rechten uit te oefenen.
		</div>
		<div @click="isGDPRVisible = false;" class="gdpr-button">Ok</div>
	</div>
-->


<gdpr
v-if="!isPnGAction"
:client="$t('gdpr.client')"
:client-adress="leadGDPR.gdprstreet?.trim() + ' ' + leadGDPR.gdprstreetnum?.trim() + ', ' + leadGDPR.gdprcp?.trim() + ' ' + leadGDPR.gdprloc?.trim() + ' ' + leadGDPR.gdprcountry?.trim()"
contact-phone=""
:contact-fname="leadGDPR.gdprfname?.trim()"
:contact-name="	leadGDPR.gdprname?.trim()"
:contact-email="leadGDPR.gdprmail?.trim()"
@gdprClosing="insertPixel"
></gdpr>


<!-- OneTrust -->
<input name="btnOneTrust" type="button" id="btnOneTrust" class="btnOneTrust" :value="$t('onetrust.cookie')" onclick="Optanon.ToggleInfoDisplay();" v-if="isPnGAction">


</template>




<script>

	/* ======== COMPONENTS ======== */
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import GDPR from '@/components/GDPR.vue';
	import LangSwitcher from '@/components/LangSwitcher.vue';
	import Macaron from '@/components/Macaron.vue';
	import ClassicFooter from '@/components/ClassicFooter.vue';
	import Popup from '@/components/popup.vue';

	/* ======== MIXINS ======== */
	// import pixel from "./mixins/pixel";
	import ga from "./mixins/ga";
	import cf from "./mixins/cf";
	import mtm from "./mixins/mtm";
	import openPDF from "./mixins/openpdf";
	import customLog from "./mixins/CustomLog";



	/* ======== OTHERS ======== */
	import config from "./config";


	export default {
		name: 'App',
		
		data() {
			return {
				idLead : config.idlead,
				leadGDPR : {},
				isGDPRVisible: true,
				isPnGAction: true,
			}
		},
		
		components: {
			LangSwitcher,
			Macaron,
			ClassicFooter,
			'gdpr': GDPR,
			Popup,
		},
		
		mixins: [
			ga,
			cf,
			mtm,
			customLog,
			openPDF,
			],


		methods: {
			getLangFromUrl () {
				this.log("Getting lang from url", 'low');
				if(!this.$i18n.locale){
					this.$i18n.locale = "nl_BE";
				}
				let urlParams = new URLSearchParams(window.location.search);
				let lang = '';
				if (urlParams.has('lang')){
					lang = urlParams.get('lang');
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						this.$i18n.locale = "fr_BE";
					}
				}
			},

			insertPixel() {
				/* INFO uncomment here to allow PIXEL insertion */
				// this.deployAndLog();
			},

			getGDPRData(idLead){
				this.log("Getting Leads for " + this.idLead, 'api');
				this.axios.get(
					`/leads/${idLead}`
					)
				.then(res => {
					this.log("I managed to get the lead for the GDPR", 'success');
					this.iGotTheGDPRLead(res);
				})
				.catch(error => {
					this.log("I cannot get the lead for the GDPR", 'alert');
					this.iCannottGetTheGDPRLead(error);
				})
				.then(()=>{
				});
			},

			iGotTheGDPRLead(res){
				this.log(res.data);
				this.leadGDPR = res.data;
			},

			iCannottGetTheGDPRLead(error){
				console.log(error.response.data.errors);
				// this.displayMessage(error.response.data.errors, 'alert');
				/* ? probably a redirection here is better : to show a adequat message. */
			},

		},
		

		beforeMount(){
			this.log("APP.VUE", "title");
			/* If not in maintenance mode */
			if(!this.$store.getters.getMaintenance){
				this.getLangFromUrl();
				this.getGDPRData(this.idLead);
			}else{
				console.log("Maintenance in progress");
			}
		},

		mounted(){
			// Added for vue devtool to work — don't remove
			window.postMessage({ devtoolsEnabled: true, vueDetected: true });
		}
	}

</script>


<style lang="scss">

	#overlay{
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 30;
	}
	.toggleButtonWrapper{
		text-align:center;

	}
	.toggleButton{
		position:initial;
		transform: translate(0%, 0%);
		font-size: 0.7em;
		margin: 20px auto;
		width: 400px;

		@media screen and (max-width: 1000px){
			white-space: nowrap;
		}
		@media screen and (max-width: 500px){

			width: 300px;
			padding: 1rem;
			line-height: 1rem;
			white-space: break-spaces;
		}
	}
	#popup{
		display: none;
		position: fixed;
		padding: 1em;
		z-index:999999;
		font-size:1rem;
		line-height:1.2em;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 700px;
		height: 600px;
		background-color: #fff;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		overflow-y: auto;
		border-radius:20px;
		cursor: ns-resize;

		@media screen and (max-width: 600px){
			width: 300px;
		}
		@media screen and (max-width: 320px){
			height: 300px;
		}

	}
	#popup::-webkit-scrollbar {
		//margin-left: 10px;
	}
</style>



